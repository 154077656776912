import React, {useState, useRef, useEffect} from "react";

const SearchableSelect = ({ togle, remove, options, placeholder, dselectedOptions, id }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [showDropdown, setShowDropdown] = useState(false);

    const dropdownRef = useRef(null);

    const filteredOptions = options.filter((option) =>
        option ? option.toLowerCase().includes(searchTerm.toLowerCase()) : false
    );

    const handleBlur = (event) => {
        if (!dropdownRef.current.contains(event.relatedTarget)) {
            setShowDropdown(false);
        }
    };

    return (
        <div style={{ width: "300px", position: "relative" }}>
            <div
                id={id}
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "5px",
                    padding: "5px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                }}
            >
                {dselectedOptions.map((option) => (
                    <span
                        key={option}
                        className={`searchable-select-option`}
                        style={{
                            backgroundColor: "#f0f0f0",
                            padding: "5px 10px",
                            borderRadius: "15px",
                            display: "inline-flex",
                            alignItems: "center",
                        }}
                    >
                        {option}
                        <button

                            className={`searchable-select-option-button`}
                            style={{
                                marginLeft: "5px",
                                background: "none",
                                border: "none",
                                cursor: "pointer",
                            }}
                            onClick={() => remove(option)}
                        >
                            ✕
                        </button>
                    </span>
                ))}
                <input
                    type="text"
                    placeholder={placeholder}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onFocus={() => setShowDropdown(true)}
                    onBlur={handleBlur}
                    style={{ flex: "1", border: "none", outline: "none" }}
                />
            </div>

            {showDropdown && filteredOptions.length > 0 && (
                <div
                    ref={dropdownRef}
                    tabIndex="-1"
                    className={"searchable-select-dropdown"}
                    onBlur={handleBlur}
                    style={{
                        position: "absolute",
                        top: "100%",
                        left: "0",
                        width: "100%",
                        maxHeight: "200px",
                        overflowY: "auto",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        backgroundColor: "#fff",
                        zIndex: "1000",
                    }}
                >
                    {filteredOptions.map((option) => (
                        <div style={{display: "flex" ,justifyContent: "space-between"}}>

                            <label
                                key={option}
                                style={{
                                    textAlign: "left",
                                    display: "block",
                                    padding: "5px",
                                    cursor: "pointer",
                                }}
                            >
                                <input
                                    type="checkbox"
                                    checked={dselectedOptions.includes(option)}
                                    onChange={() => togle(option)}
                                    style={{marginRight: "5px"}}
                                />
                                {option}
                            </label>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SearchableSelect;
